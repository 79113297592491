import { Row, Col } from "antd";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import {
  FooterSection,
  NavLink,
  LogoContainer,
  Para,
  Empty,
} from "./styles";


const Footer = ({ t }: any) => {

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={8} md={8} sm={12} xs={12}>
              Address
                            <Empty />

              <Para>S.O. PLATFORM LIMITED</Para>
              <Para>KOSTA ANAXAGORA,30</Para>
              <Para>STORIKON BUILDING, OFFICE 101</Para>
              <Para>P.C. 2014, NICOSIA, CYPRUS</Para>
            </Col>
                        <Col>
              <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="180px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            </Col>

          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default Footer;
